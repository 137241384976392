import React, {useState} from 'react';
import {convertDateFormat, isDateInFuture} from "./Utils";

const ExpandableTableRow = ({course, formData, handleRadioChange, className}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleRow = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <React.Fragment>
            <tr id={course.course_id} className={className}>
                <td>
                    <button onClick={toggleRow}>
                        {isExpanded ? '-' : '+'}
                    </button>
                </td>
                <td>

                    {course.course_name}</td>
                <td>{convertDateFormat(course.start_date)}</td>
                <td className="right-align">${course.total_cost}</td>
                <td className="right-align">
                    {(course.available_slots > 0 && isDateInFuture(course.registration_deadline)) ? (
                        <input
                            type="radio"
                            name="courseSelection"
                            value={course.course_id}
                            checked={formData.courseId === course.course_id}
                            onChange={() => handleRadioChange(course.course_id, course.course_name)}

                        />) : (<>Call<sup>*</sup></>)}

                </td>
            </tr>
            {isExpanded && (
                <tr id={course.course_id + .1} className={className}>
                    <td colSpan="5">{course.description}</td>
                </tr>)}
        </React.Fragment>

    );
};

export default ExpandableTableRow;
